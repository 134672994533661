module controllers {
    export module lc {

        interface ILetterOfCreditCtrlScope extends ng.IScope {
        }

        interface ILetterOfCreditParams extends ng.ui.IStateParamsService {
        }

        export class letterOfCreditListCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                "$uibModal",
                "letterOfCreditService",
                "$state",
                "$stateParams",
                "supplierService",
                "currencyService",
                "statusService",
                "classificationValueService",
                "bankService",
                "$transitions",
            ];
            IsLoading: boolean = false;
            gridApi: uiGrid.IGridApi;
            apiList: uiGrid.IGridApi;
            selected: uiGrid.IGridRow;
            selectedRowsArray: Array<interfaces.lc.ILetterOfCreditDisplay> = [];
            allDirtyRows: Array<interfaces.lc.ILetterOfCreditDisplay>;
            searchAccordian: boolean = false;
            myHook: any;

            lcSearch: interfaces.lc.ILetterOfCreditSearch = {
            };

            dropdownsObject = {
                LCStatusList: Array<interfaces.applicationcore.IDropdownModel>(),
            };

            statusList: interfaces.applicationcore.IDropdownModel[] = [];

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            ctrlName: string;
            ownerEntityId: number;
            sortName: string;
            sortDirection: string;

            constructor(private $scope: ILetterOfCreditCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private letterOfCreditService: interfaces.lc.ILetterOfCreditService,
                private $state: ng.ui.IStateService,
                private $stateParams: ILetterOfCreditParams,
                private supplierService: interfaces.master.ISupplierService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private statusService: interfaces.master.IStatusService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public bankService: interfaces.master.IBankService,
                private $transitions: ng.ui.core.ITransition
            ) {
                this.lcSearch = {
                    Status: -1
                };

                this.ctrlName = 'Letter Of Credit List Controller';

                this.loadControls();

                this.loadLetterOfCredits();

                this.myHook = $transitions.onSuccess({
                    to: 'auth.LetterOfCredit',
                    from: 'auth.LetterOfCredit.**'
                }, () => {
                    this.loadLetterOfCredits();
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });
            }

            loadLetterOfCredits() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'letterOfCreditList'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.lcSearch, (n, key) => {
                            if (n["Id"]) {
                                searchObject.filters.push({ Name: key, Value: n.Id });
                            } else {
                                searchObject.filters.push({ Name: key, Value: n });
                            }
                        });

                        if (this.apiList) {
                            var grid = this.apiList.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.letterOfCreditService.getList().query(params, (result: Array<interfaces.lc.ILetterOfCreditDisplay>) => {
                            this.gvwLCs.data = result;
                            this.selectedRowsArray = [];
                            if (result[0]) {
                                this.gvwLCs.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwLCs.totalItems = 0;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            printDocuments() {
                if (this.apiList && this.apiList.selection.getSelectedRows().length > 0) {
                    var selected = this.apiList.selection.getSelectedRows()[0];

                    this.$state.go("auth.LetterOfCredit.Update.Documents", { lcId: selected.Id });

                } else {
                    this.generalService.displayMessage("Please select a Letter Of Credit", Enum.EnumMessageType.Warning);
                }
            }

            public gvwLCs: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableVerticalScrollbar: 1,
                useExternalPagination: true,
                enableGridMenu: false,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: false,
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['EDIT', 'NUMRECS'],
                onRegisterApi: (gridApi) => {
                    this.registerGridApi(gridApi)

                    this.apiList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiList.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" ng-click="grid.appScope.letterOfCreditListCtrl.editLC(row.entity.Id)" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "Code",
                    displayName: "Applicant Ref.",
                    field: "Code",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "Version",
                    displayName: "Version",
                    field: "Version",
                    enableFiltering: false,
                    width: 100,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },{
                    name: "CreateDate",
                    displayName: "Create Date",
                    field: "CreateDate",
                    width: 100,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="CreateDate"></gts-date>
                        </div>`,
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"'
                },
                {
                name: "Beneficiary",
                displayName: "Beneficiary",
                field: "Beneficiary",
                width: 200,
                filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "Amount",
                    displayName: "Amount",
                    field: "Amount",
                    width: 100,
                    enableFiltering: false,
                    cellClass: 'text-right',
                    cellFilter: "number: 2"
                },
                {
                    name: "Currency",
                    displayName: "Currency",
                    field: "Currency",
                    width: 100,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "StatusDesc",
                    displayName: "Status",
                    field: "Status",
                    width: 200,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "StatusDate",
                    displayName: "Status Date",
                    field: "StatusDate",
                    width: 100,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="StatusDate"></gts-date>
                    </div>`,
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"'
                },
                {
                    name: "IssuingBank",
                    displayName: "Issuing Bank",
                    field: "IssuingBank",
                    width: 200,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "AdvisingBank",
                    displayName: "Advising Bank",
                    field: "AdvisingBank",
                    width: 200,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "ReimbursingBank",
                    displayName: "Reimbursing Bank",
                    field: "ReimbursingBank",
                    width: 200,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "ResponsibleUserName",
                    displayName: "Responsible User",
                    field: "ResponsibleUserName",
                    width: 200,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "HasDiscrepancies",
                    displayName: "Discrepancies?",
                    field: "HasDiscrepancies",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }
                ]
            };

            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiList = gridApi;

                this.apiList.core.on.filterChanged(this.$scope, () => { this.comListFilterChange(gridApi) });
                this.apiList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.comListShortChange(gridApi);
                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });

                this.apiList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.apiList.core.on.rowsRendered(this.$scope, () => {

                    for (let selectedRow of this.selectedRowsArray) {

                        for (var row of this.gvwLCs.data) {

                            if (selectedRow.Id === row.Id) {
                                this.apiList.selection.selectRow(row);
                            }
                        }
                    }
                });

                this.apiList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.selected = selected;

                    if (!selected.isSelected) {

                        for (let selectedRow of this.selectedRowsArray) {
                            if (selectedRow.Id === selected.entity.Id) {
                                this.selectedRowsArray.splice(this.selectedRowsArray.indexOf(selectedRow), 1);
                            }
                        }
                    }
                    else {
                        var Exists: number = 0;
                        for (let selectedRow of this.selectedRowsArray) {
                            if (selectedRow.Id === selected.entity.Id) {
                                Exists = 1;
                            }
                        }

                        if (Exists === 0) {
                            this.selectedRowsArray.push(selected.entity);
                        }
                    }
                });

                this.$timeout(() => {
                    if (this.selected)
                        this.apiList.selection.selectRow(this.selected.entity);
                });
            }

            comListShortChange(gridApi) {
                this.DoSearch();
            }

            comListFilterChange(gridApi: uiGrid.IGridApi) {
                this.DoSearch();
            }

            public editLC(id: number) {
                this.$state.go("auth.LetterOfCredit.Update", { lcId: id });
            }

            public createLC() {
                this.$state.go("auth.LetterOfCredit.Create", { locId: 0 });
            }


            ListPageChange(newPage, pageSize) {
                var dirtyRows = _.map(this.apiList.rowEdit.getDirtyRows(), (value) => { return value.entity; });
                if (this.allDirtyRows) {
                    _.forEach(dirtyRows, (value) => {
                        //If a dirty row exists in the new dirty rows then overwrite the old one.
                        var row: interfaces.lc.ILetterOfCreditDisplay = _.find(this.allDirtyRows, _.matchesProperty('Id', value.Id));

                        if (row) {
                            //Row exists with the same Id.
                            var i: number = _.indexOf(this.allDirtyRows, row);
                            this.allDirtyRows[i] = row;
                        }
                        else {
                            this.allDirtyRows = _.concat(this.allDirtyRows, value);
                        }
                    });
                }
                else {
                    this.allDirtyRows = dirtyRows;
                }

                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            loadStatuses() {
                return this.statusService.getStatusDropdownList().query({ type: Enum.EnumStatusType.LetterOfCredit }, (result) => {
                    this.statusList = result;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    deferred.resolve(true);

                }, (failureData) => {

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            loadCurrencies() {
                return this.currencyService.getCurrencyDropdownList().query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadSuppliers(searchText: string) {
                return this.supplierService.getSupplierDropdown(this.$rootScope.Entity.Id, searchText).query({
                }, () => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadControls() {
                let controlPromises = [
                    this.loadStatuses()
                ]

                this.$q.all(controlPromises).then((data) => {
                    this.DoSearch();
                });

            }

            loadBanks() {
                return this.bankService.getDropdownList(this.$rootScope.Entity.Id, "").query({
                }, (resultList) => {
                }).$promise;
            }

            showAll_click() {
                this.lcSearch = {
                };
                if (this.apiList !== undefined) {
                    this.apiList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiList.grid.resetColumnSorting(null);
                    });
                }

                this.searchAccordian = false;
                this.DoSearch();
            }

            search_click() {
                this.searchAccordian = false;
                this.DoSearch();
            }

            downloadToExcel() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.lcSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiList) {

                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject
                }

                this.letterOfCreditService.getLCListExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwLCs.data = [];
                    this.gvwLCs.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            DoSearch() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'letterOfCreditList'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.lcSearch, (n, key) => {
                            if (n) {
                                if (n["Id"]) {
                                    searchObject.filters.push({ Name: key, Value: n.Id });
                                } else {
                                    if (n instanceof moment) {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    } else {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    }
                                }
                            }
                        });

                        if (this.apiList) {
                            var grid = this.apiList.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.letterOfCreditService.getList().query(params, (result: Array<interfaces.lc.ILetterOfCreditDisplay>) => {
                            var currentDirtyRows: Array<interfaces.lc.ILetterOfCreditDisplay> = [];

                            _.forEach(this.allDirtyRows, function (value) {
                                var row: interfaces.lc.ILetterOfCreditDisplay = _.find(result, _.matchesProperty('Id', value.Id));

                                if (row) {
                                    var i: number = _.indexOf(result, row);
                                    result[i] = value;
                                    currentDirtyRows.push(result[i]);
                                }
                            });

                            this.gvwLCs.data = result;
                            this.selectedRowsArray = [];

                            if (currentDirtyRows.length > 0) {
                                this.$timeout(() => {
                                    _.forEach(currentDirtyRows, (value) => {
                                        this.apiList.rowEdit.setRowsDirty(currentDirtyRows);
                                    });
                                });
                            }

                            if (result[0]) {
                                this.gvwLCs.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwLCs.totalItems = 0;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }
        }

        angular.module("app").controller("letterOfCreditListCtrl", controllers.lc.letterOfCreditListCtrl);
    }
}